var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"section",attrs:{"id":"home-slider"}},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content"},[_c('client-only',[_c('vue-glide',{attrs:{"options":{
						type: 'carousel',
						focusAt: '0',
						perView: 1,
						startAt: 0,
						bound: true,
						gap: 0,
						autoplay: 15000,
						hoverpause: true,
						rewind: true,
						touchDistance: 100000,
						dragDistance: 100000,
						animationDuration: 1500
					}}},[_vm._l((_vm.data.Slides),function(slide,index){return [_c('vue-glide-slide',{key:index},[_c('div',{staticClass:"slide-wrap"},[_c('div',{staticClass:"bg"},[(slide.BackgroundVideo)?[_c('VideoStrapi',{attrs:{"src":slide.BackgroundVideo,"poster":slide.BackgroundImage.url}})]:[(slide.BackgroundImage)?_c('ResponsiveImageStrapi',{attrs:{"src":slide.BackgroundImage}}):_vm._e()]],2),_vm._v(" "),_c('div',{ref:"content",refInFor:true,staticClass:"content"},[_c('h2',[_vm._v(_vm._s(slide.Headline))]),_vm._v(" "),_c('div',{staticClass:"subtitle",domProps:{"innerHTML":_vm._s(slide.Content)}}),_vm._v(" "),_vm._l((slide.Button),function(button,index){return [(button.Copy)?_c('nuxt-link',{key:index,staticClass:"btn",attrs:{"to":{ path: button.Url }}},[_vm._v(_vm._s(button.Copy))]):_vm._e()]})],2)])])]}),_vm._v(" "),_c('template',{slot:"control"},[_c('button',{staticClass:"glide__arrow glide__arrow--left",attrs:{"data-glide-dir":"<","name":"previous"}},[_c('span',[_c('em',[_vm._v("Previous")])])]),_vm._v(" "),_c('button',{staticClass:"glide__arrow glide__arrow--right",attrs:{"data-glide-dir":">","name":"next"}},[_c('span',[_c('em',[_vm._v("Next")])])])]),_vm._v(" "),_c('div',{staticClass:"glide__bullets",attrs:{"slot":"control"},slot:"control"},[_c('div',{attrs:{"data-glide-el":"controls[nav]"}},[_vm._l((_vm.data.Slides),function(slide,index){return [_c('button',{key:index,staticClass:"glide__bullet",attrs:{"data-glide-dir":'=' + index,"name":'slide ' + index}},[_c('span',[_vm._v("Slide "+_vm._s(index))])])]})],2)])],2)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }