//
//
//
//
//
//
//
//
//

export default {
	props: {
		src: {
			type: String,
			default: null,
		},
		poster: {
			type: String,
			default: null,
		},
		classname: {
			type: String,
			default: null,
		}
	},
	data() {
		return {
			observer: null,
			imageloaded: false,
		}
	},
	computed: {
		getSrc() {
			return this.src
		},
		getPoster() {
			// return this.poster
			return `${require(`~/assets/${this.poster}`)}`
		}
	},
	mounted() {
		if ('isIntersecting' in window.IntersectionObserverEntry.prototype) {
			const options = {
				root: document.querySelector('.page:last-of-type'),
				rootMargin: '0px',
				threshold: 0.25,
			}
			this.observer = new IntersectionObserver(this.updateImg, options)
			// console.log("add observer: ", this.$refs.video);
			this.observer.observe(this.$refs.video)
		} else {
			// fallback for IE / Opera / Baidu
			this.setFallback()
		}
	},
	beforeDestroy() {
		// console.log(`IMAGE -beforeDestroy`)
		if (this.observer) {
			this.unobserve()
			this.observer = null
		}
	},
	methods: {
		imageLoad(event) {
			this.imageloaded = true
		},
		unobserve() {
			this.observer.unobserve(this.$refs.video)
		},
		setFallback() {
			const src = this.$refs.videosrc.getAttribute('data-src')
			if (src) {
				this.$refs.videosrc.src = src
			}
			this.$refs.videosrc.removeAttribute('data-src')
		},
		updateImg(observerEntry) {
			// console.log("observerEntry: ", observerEntry[0])
			if (this.imageloaded) {
				return
			}

			if (observerEntry[0].intersectionRatio > 0) {
				const src = this.$refs.videosrc.getAttribute('data-src')
				if (src) {
					this.$refs.videosrc.src = src
				}
				this.$refs.videosrc.removeAttribute('data-src')
				this.imageloaded = true
				this.unobserve()
				this.$refs.video.load()
			}
		},
	},
}
