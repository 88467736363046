//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gsap from 'gsap'
import ScrollTrigger from '../../node_modules/gsap/dist/ScrollTrigger'

import ResponsiveImageStrapi from '@/components/modules/ResponsiveImageStrapi'
import VideoStrapi from '@/components/modules/VideoStrapi'

export default {
	props: {
		data: {
			type: Object,
			default: null,
		},
	},
	components: {
		ResponsiveImageStrapi,
		VideoStrapi,
	},
	data() {
		return {
			showContent: false
		}
	},
	computed: {
		slides() {
			return this.slidedata
		},
	},
	mounted() {
		this.$nextTick(this.Intro)
	},
	methods: {
		setFormvisibility() {
			this.showContent = false
		},
		Intro() {
			setTimeout(() => {
				gsap.registerPlugin(ScrollTrigger)
				const tl = gsap.timeline({
					scrollTrigger: {
						trigger: this.$refs.section,
						start: "top center",
						// markers: true
					}
				});
				tl.fromTo(this.$refs.content, {
					autoAlpha: 0,
					// y: 200
				}, {
					duration: 1.5,
					autoAlpha: 1,
					// y: 0
				});
			}, 0)
		},
	},
}
